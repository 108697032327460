import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

// Initial state
const initialState = {
  isLoading: true,
  progress: 0,
};

// Actual Slice
export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setProgress(state, action) {
      state.progress = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.loader,
      };
    },
  },
});

export const { setIsLoading, setProgress } = loaderSlice.actions;

export default loaderSlice.reducer;
