import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

// Initial state
const initialState = {
  projectImage: null,
  createProjectImage: false,
  projectName: "",
  projectId: "",
  render4ktextures: false,
  rendered4ktextures: {},
};

// Actual Slice
export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    // configurator 4k textures
    resetRenderedTextures(state) {
      state.rendered4ktextures = {};
    },
    setRendered4KTextures(state, action) {
      state.rendered4ktextures[action.payload.name] = action.payload.objectURL;
    },
    setRender4kTextures(state, action) {
      state.render4ktextures = action.payload;
    },
    // project details
    setProjectId(state, action) {
      state.projectId = action.payload;
    },
    setProjectName(state, action) {
      state.projectName = action.payload;
    },
    setProjectImage(state, action) {
      state.projectImage = action.payload;
    },
    setCreateProjectImage(state, action) {
      state.createProjectImage = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.project,
      };
    },
  },
});

export const {
  setProjectName,
  setProjectImage,
  setCreateProjectImage,
  setProjectId,
  resetRenderedTextures,
  setRendered4KTextures,
  setRender4kTextures,
} = projectSlice.actions;

export default projectSlice.reducer;
