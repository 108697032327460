function hexToHSL(H) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return l;
}

function generateUniqueId() {
  const timestamp = new Date().getTime();
  const randomNum = Math.floor(Math.random() * 10000);
  return `${timestamp}-${randomNum}`;
}

function applyCrop(ref, width, height, name = "default") {
  if (ref) {
    const crop = getCrop(ref.attrs.image, { width: width, height: height }, "center-middle");
    return crop;
  }
}

function getCrop(image, size) {
  const width = size.width;
  const height = size.height;
  const aspectRatio = width / height;

  let newWidth;
  let newHeight;

  const imageRatio = image.width / image.height;

  if (aspectRatio >= imageRatio) {
    newWidth = image.width;
    newHeight = image.width / aspectRatio;
  } else {
    newWidth = image.height * aspectRatio;
    newHeight = image.height;
  }

  let x = (image.width - newWidth) / 2;
  let y = (image.height - newHeight) / 2;

  return {
    x: x,
    y: y,
    width: newWidth,
    height: newHeight,
  };
}

function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function serializeArr(arr) {
  const serializedArr = arr.map((obj) => obj.toString());
  return serializedArr;
}

function deserializeArr(arr) {
  const deserializedArr = arr.map((obj) => eval("(" + obj + ")"));
  return deserializedArr;
}

function rgbToHex(rgbColor) {
  // Parse the RGB color values
  const match = rgbColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

  if (!match) {
    return null;
  }

  const r = parseInt(match[1], 10);
  const g = parseInt(match[2], 10);
  const b = parseInt(match[3], 10);

  // Convert the RGB values to hexadecimal and ensure they have two digits
  const redHex = r < 16 ? "0" + r.toString(16) : r.toString(16);
  const greenHex = g < 16 ? "0" + g.toString(16) : g.toString(16);
  const blueHex = b < 16 ? "0" + b.toString(16) : b.toString(16);

  // Combine the values to create the hexadecimal color code
  const hexColor = "#" + redHex + greenHex + blueHex;

  return hexColor;
}

function hexToRgb(hex) {
  // Remove the hash (#) character if present
  hex = hex.replace(/^#/, "");

  // Parse the hex string to get the RGB values
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  // Return the RGB values as an object
  return { r: r, g: g, b: b };
}

function hexToRgba(hex, alpha) {
  // Remove the hash (#) character if present
  hex = hex.replace(/^#/, "");

  // Parse the hex string to get the RGB values
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  // Ensure the alpha value is in the range [0, 1]
  alpha = typeof alpha === "undefined" ? 1 : Math.min(1, Math.max(0, alpha));

  // Return the RGBA values as a string
  return { r: r, g: g, b: b, a: alpha };
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;

  if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

function createGoogleFontsURL(fontData) {
  const baseUrl = "https://fonts.googleapis.com/css2";
  const family = encodeURIComponent(fontData.family);
  // Map 'regular' to '400' and 'italic' to '400italic'
  const variants = fontData.variants.map((variant) => {
    if (variant === "regular") return "0,400";
    if (variant === "italic") return "1,400";
    const weight = variant.match(/\d+/)[0]; // Extract the numeric weight
    const style = variant.includes("italic") ? "1" : "0";
    return `${style},${weight}`;
  });

  // Remove duplicates
  const uniqueVariants = [...new Set(variants)];

  // Sort variants to ensure order is 0,100; 0,300; ... ; 1,100; 1,300; ...
  uniqueVariants.sort((a, b) => {
    const [styleA, weightA] = a.split(",");
    const [styleB, weightB] = b.split(",");
    if (styleA === styleB) {
      return parseInt(weightA) - parseInt(weightB);
    }
    return parseInt(styleA) - parseInt(styleB);
  });

  const variantsString = `ital,wght@${uniqueVariants.join(";")}`;

  return `${baseUrl}?family=${family}:${variantsString}&display=swap`;
}

function findObjectByKey(array, key, value) {
  return array.find((item) => item[key] === value);
}

const readLS = (key) => localStorage.getItem(key);
const writeLS = (key, value) => localStorage.setItem(key, value);
const eraseLS = (key) => localStorage.removeItem(key);

export const getCookie = (name) => {
  const matches = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`);
  return matches ? matches[1] : null;
};

export const setCookie = (name, value, days) => {
  let expires = "";

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }

  document.cookie = `${name}=${value}${expires}; path=/`;
};

export const eraseCookie = (name) => {
  setCookie(name, "", -1);
};

export {
  findObjectByKey,
  createGoogleFontsURL,
  hexToHSL,
  generateUniqueId,
  applyCrop,
  getCrop,
  capitalizeFirstLetter,
  serializeArr,
  deserializeArr,
  rgbToHex,
  hexToRgb,
  hexToRgba,
  dataURItoBlob,
  readLS,
  writeLS,
  eraseLS,
};
