import { createSlice } from "@reduxjs/toolkit";
// import {appState}
import { HYDRATE } from "next-redux-wrapper";

// Initial state
const initialState = {
  backgroundColor: "#000000",
  selectedHDR: "/img/potsdamer_platz_1k.hdr",
  envIntensity: 0.2,
  extraSettings: { exposure: 1 },
  renderImages: false,
  imageSettings: {
    imageFormat: "png",
    imageQuality: "4k",
  },
  transparentBackground: false,
  hdrBackground: false,
  lightSettings: {
    flashlightEnabled: false,
    flashlightOffsetY: 0.5,
    flashlightIntensity: 1,
    flashlightAdaptive: false,
    //
    ambientLightEnabled: true,
    ambientLightIntensity: 0.15,
    ambientLightColor: "#ffffff",
    //
    topSpotlightEnabled: true,
    topSpotlightCastShadows: true,
    topSpotlightIntensity: 1,
    topSpotlightColor: "#ffffff",
    topSpotlightAttenuation: 5,
    topSpotlightX: 0,
    topSpotlightZ: 0,
    //
    frontSpotlightEnabled: true,
    frontSpotlightCastShadows: true,
    frontSpotlightIntensity: 0.5,
    frontSpotlightColor: "#ffffff",
    frontSpotlightAttenuation: 5,
    frontSpotlightX: 0,
    frontSpotlightY: 0.5,
    //
    leftSpotlightEnabled: false,
    leftSpotlightCastShadows: true,
    leftSpotlightIntensity: 0.5,
    leftSpotlightColor: "#ffffff",
    leftSpotlightAttenuation: 5,
    leftSpotlightY: 0,
    leftSpotlightZ: 0,
    //
    rightSpotlightEnabled: false,
    rightSpotlightCastShadows: true,
    rightSpotlightIntensity: 0.5,
    rightSpotlightColor: "#ffffff",
    rightSpotlightAttenuation: 5,
    rightSpotlightY: 0,
    rightSpotlightZ: 0,
    //
    backSpotlightEnabled: true,
    backSpotlightCastShadows: true,
    backSpotlightIntensity: 0.5,
    backSpotlightColor: "#ffffff",
    backSpotlightAttenuation: 5,
    backSpotlightY: 0.5,
    backSpotlightX: 0,
  },
  cameraSettings: {
    cameraPosition: { x: 0, y: 0, z: 3 },
    cameraZoom: 1.5,
    cameraFov: 35,
  },
  aspectRatio: "1:1",
  fogEnabled: false,
  groundSettings: {
    groundEnabled: false,
    groundColor: "#fff",
  },
  modelSettings: {
    roughness: 0.9,
    normalScale: 1,
    offsetX: 0,
    offsetY: 0,
    offsetZ: 0,
  },
};

// Actual Slice
export const configurator3DSlice = createSlice({
  name: "configurator3D",
  initialState,
  reducers: {
    setStructure3d(state, action) {
      state.backgroundColor = action.payload.backgroundColor;
      state.selectedHDR = action.payload.selectedHDR;
      state.envIntensity = action.payload.envIntensity;
      state.extraSettings = action.payload.extraSettings;
      state.renderImages = action.payload.renderImages;
      state.imageSettings = action.payload.imageSettings;
      state.transparentBackground = action.payload.transparentBackground;
      state.hdrBackground = action.payload.hdrBackground;
      state.lightSettings = action.payload.lightSettings;
      state.cameraSettings = action.payload.cameraSettings;
      state.aspectRatio = action.payload.aspectRatio;
      state.fogEnabled = action.payload.fogEnabled;
      state.groundSettings = action.payload.groundSettings;
      state.modelSettings = action.payload.modelSettings;
    },
    setImageSettings(state, action) {
      state.imageSettings = action.payload;
    },
    setEnvIntesnity(state, action) {
      state.envIntensity = action.payload;
    },
    setModelSettings(state, action) {
      state.modelSettings = action.payload;
    },
    setGroundSettings(state, action) {
      state.groundSettings = action.payload;
    },
    setFogEnabled(state, action) {
      state.fogEnabled = action.payload;
    },
    setAspectRatio(state, action) {
      state.aspectRatio = action.payload;
    },
    setCameraSettings(state, action) {
      state.cameraSettings = action.payload;
    },
    setLightSettings(state, action) {
      state.lightSettings = action.payload;
    },
    resetConfigurator3D(state) {
      state.backgroundColor = "#000000";
      state.selectedHDR = "/img/potsdamer_platz_1k.hdr";
      state.extraSettings = { exposure: 1 };
      state.renderImages = false;
      state.transparentBackground = false;
      state.hdrBackground = false;
    },
    handleTransparentHdrBg(state, action) {
      state.transparentBackground = action.payload.transparentBackground;
      state.hdrBackground = action.payload.hdrBackground;
    },
    resetRenderedTextures(state) {
      state.rendered4ktextures = {};
    },
    setHdrBackground(state, action) {
      state.hdrBackground = action.payload;
    },
    setBackgroundColor(state, action) {
      state.backgroundColor = action.payload;
    },
    setSelectedHDR(state, action) {
      state.selectedHDR = action.payload;
    },
    setExtraSettings(state, action) {
      state.extraSettings = action.payload;
    },
    setRenderImages(state, action) {
      state.renderImages = action.payload;
    },
    setTransparentBackground(state, action) {
      state.transparentBackground = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.configurator3D,
      };
    },
  },
});

export const {
  setFlashlight,
  setImageSettings,
  setEnvIntesnity,
  setModelSettings,
  setAspectRatio,
  setCameraSettings,
  setLightSettings,
  resetConfigurator3D,
  handleTransparentHdrBg,
  resetRenderedTextures,
  setRendered4KTextures,
  setRender4kTextures,
  setBackgroundColor,
  setSelectedHDR,
  setExtraSettings,
  setRenderImages,
  setTransparentBackground,
  setHdrBackground,
  setFogEnabled,
  setGroundSettings,
  setStructure3d,
} = configurator3DSlice.actions;

export default configurator3DSlice.reducer;
