import btnStyles from "./Buttons.module.css";
import inputStyles from "./Input.module.css";
import wrapperStyles from "./Wrapper.module.css";
import colorpickerStyles from "./Colorpicker.module.css";
import loaderStyles from "./Loader.module.css";
import Image from "next/image";
import { useEffect, useState, useRef, Suspense } from "react";
import { HexColorPicker, RgbaColorPicker } from "react-colorful";
import { gsap } from "gsap";
// r3f
import { Canvas, useThree, useFrame } from "@react-three/fiber";
import {
  OrbitControls,
  PerspectiveCamera,
  Stats,
  Center,
  Preload,
  useProgress,
  SoftShadows,
  AdaptiveDpr,
  Environment,
  useTexture,
  Plane,
  Loader as DreiLoader,
  OrthographicCamera,
} from "@react-three/drei";
import { sRGBEncoding, ACESFilmicToneMapping, LinearEncoding } from "three";
// redux
import { useSelector, useDispatch } from "react-redux";
import { setIsLoading, setProgress } from "@/features/loaderSlice";
import { setRenderImages } from "@/features/configurator3DSlice";
import { setCreateProjectImage, setProjectImage } from "@/features/projectSlice";
import { dataURItoBlob } from "@/misc/utils";
import { useWindowSize } from "@uidotdev/usehooks";
import { useGSAP } from "@gsap/react";
import {
  LayerIcon,
  CommandIcon,
  DeleteIcon,
  UpIcon,
  DownIcon,
  UndoIcon,
  RedoIcon,
  BlendModeIcon,
  ShadowIcon,
  DuplicateIcon,
  FillIcon,
  RotateIcon,
  DropDownIcon,
  BackIcon,
  SunIcon,
  SpotlightIcon,
  GoogleIcon,
  SettingsIcon,
} from "../Icons";

function Icons({ icon }) {
  switch (icon) {
    case "duplicate":
      return <DuplicateIcon />;
    case "delete":
      return <DeleteIcon />;
    case "up":
      return <UpIcon />;
    case "down":
      return <DownIcon />;
    case "undo":
      return <UndoIcon />;
    case "redo":
      return <RedoIcon />;
    case "blendmode":
      return <BlendModeIcon />;
    case "shadow":
      return <ShadowIcon />;
    case "fill":
      return <FillIcon />;
    case "layer":
      return <LayerIcon />;
    case "settings":
      return <SettingsIcon />;
  }
}

export function ButtonSVG({
  callback,
  state = false,
  name = "",
  children,
  disabled = false,
  text = null,
  hoverText = null,
  hoverTextRight = false,
  hoverTextLeft = false,
  isBackBtn = false,
  shortcutKey = "",
  isLoading = false,
  isPrimary = false,
  isAccent = false,
  noHover = false,
  type = "button",
  counter = null,
}) {
  const ref = useRef();
  const hover = useRef();

  const { contextSafe } = useGSAP({ scope: hover });

  const onHover = contextSafe(() => {
    if (hoverText) {
      gsap.to(hover.current, {
        opacity: 1,
        duration: 0.3,
        scale: 1,
      });
    }
  });

  const onMouseLeave = contextSafe(() => {
    if (hoverText) {
      gsap.to(hover.current, {
        opacity: 0,
        duration: 0.3,
        scale: 0.5,
      });
    }
  });

  const onMouseMove = contextSafe((e) => {
    if (hoverText) {
      if (hoverTextRight) {
        const y = e.clientY - e.target.getBoundingClientRect().top - hover.current.clientHeight / 2;

        gsap.to(hover.current, {
          y: `${y}px`,
        });
      } else if (hoverTextLeft) {
        const y = e.clientY - e.target.getBoundingClientRect().top - hover.current.clientHeight / 2;

        gsap.to(hover.current, {
          y: `${y}px`,
        });
      } else {
        gsap.set(hover.current, {
          y: `${-55}px`,
        });
      }
    }
  });

  useGSAP(
    () => {
      if (hoverTextRight) {
        gsap.set(hover.current, {
          x: `${ref.current?.clientWidth + 75}px`,
        });
      } else if (hoverTextLeft) {
        gsap.set(hover.current, {
          x: `${-ref.current?.clientWidth - 75}px`,
        });
      } else {
        gsap.set(hover.current, {
          y: `${-55}px`,
        });
      }
    },
    { dependencies: [hoverTextRight, hoverTextLeft, ref.current] }
  );

  return (
    <button
      type={type}
      ref={ref}
      onMouseEnter={onHover}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onClick={callback}
      className={`${btnStyles.btn} ${state ? btnStyles.btn__active : ""} ${
        disabled ? btnStyles.btn__disabled : ""
      } ${isBackBtn ? btnStyles.btn__back : ""} ${isPrimary ? btnStyles.btn__primary : ""} ${
        noHover ? btnStyles.no__hover : ""
      } ${isAccent ? btnStyles.btn__accent : ""}`}
      name={name}
    >
      {counter > 0 && <div className={btnStyles.btn__counter}>{counter}</div>}
      {hoverText && (
        <div className={btnStyles.tooltip} ref={hover}>
          {hoverText}
          {shortcutKey && (
            <FlexWrapper padding="0" gap=".25rem">
              <CommandIcon />
              <Icons icon={shortcutKey} />
            </FlexWrapper>
          )}
        </div>
      )}
      {isLoading ? <LoadingIcon isPrimary={isPrimary} /> : children}
      {text && <span>{text}</span>}
    </button>
  );
}

export function ButtonToggle({
  callback,
  state = false,
  name = "",
  children,
  disabled = false,
  text = null,
  hoverText = null,
  hoverTextRight = false,
  hoverTextLeft = false,
  shortcutKey = "",
  isLoading = false,
  isPrimary = false,
  type = "button",
  counter = null,
}) {
  const ref = useRef();
  const hover = useRef();

  const { contextSafe } = useGSAP({ scope: hover });

  const onHover = contextSafe(() => {
    if (hoverText) {
      gsap.to(hover.current, {
        opacity: 1,
        duration: 0.3,
        scale: 1,
      });
    }
  });

  const onMouseLeave = contextSafe(() => {
    if (hoverText) {
      gsap.to(hover.current, {
        opacity: 0,
        duration: 0.3,
        scale: 0.5,
      });
    }
  });

  const onMouseMove = contextSafe((e) => {
    if (hoverText) {
      if (hoverTextRight) {
        const y = e.clientY - e.target.getBoundingClientRect().top - hover.current.clientHeight / 2;

        gsap.to(hover.current, {
          y: `${y}px`,
        });
      } else if (hoverTextLeft) {
        const y = e.clientY - e.target.getBoundingClientRect().top - hover.current.clientHeight / 2;

        gsap.to(hover.current, {
          y: `${y}px`,
        });
      } else {
        gsap.set(hover.current, {
          y: `${-55}px`,
        });
      }
    }
  });

  useGSAP(
    () => {
      if (hoverTextRight) {
        gsap.set(hover.current, {
          x: `${ref.current?.clientWidth + 75}px`,
        });
      } else if (hoverTextLeft) {
        gsap.set(hover.current, {
          x: `${-ref.current?.clientWidth - 75}px`,
        });
      } else {
        gsap.set(hover.current, {
          y: `${-55}px`,
        });
      }
    },
    { dependencies: [hoverTextRight, hoverTextLeft, ref.current] }
  );

  return (
    <button
      type={type}
      ref={ref}
      onMouseEnter={onHover}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onClick={callback}
      className={`${btnStyles.btn__secondary} ${state ? btnStyles.btn__secondary__active : ""} ${
        disabled ? btnStyles.btn__disabled : ""
      }`}
      name={name}
    >
      {counter > 0 && <div className={btnStyles.btn__counter}>{counter}</div>}
      {hoverText && (
        <div className={btnStyles.tooltip} ref={hover}>
          {hoverText}
          {shortcutKey && (
            <FlexWrapper padding="0" gap=".25rem">
              <CommandIcon />
              <Icons icon={shortcutKey} />
            </FlexWrapper>
          )}
        </div>
      )}
      {isLoading ? <LoadingIcon isPrimary={isPrimary} /> : children}
      {text && <span>{text}</span>}
    </button>
  );
}

function LoadingIcon({ isPrimary }) {
  return (
    <div className={`${loaderStyles.loader__inner} ${loaderStyles.loader__icon}`}>
      <div className={`${loaderStyles.dot} ${isPrimary && loaderStyles.primary}`}></div>
      <div className={`${loaderStyles.dot} ${isPrimary && loaderStyles.primary}`}></div>
      <div className={`${loaderStyles.dot} ${isPrimary && loaderStyles.primary}`}></div>
      <div className={`${loaderStyles.dot} ${isPrimary && loaderStyles.primary}`}></div>
      <div className={`${loaderStyles.dot} ${isPrimary && loaderStyles.primary}`}></div>
      <div className={`${loaderStyles.dot} ${isPrimary && loaderStyles.primary}`}></div>
      <div className={`${loaderStyles.dot} ${isPrimary && loaderStyles.primary}`}></div>
      <div className={`${loaderStyles.dot} ${isPrimary && loaderStyles.primary}`}></div>
    </div>
  );
}

export function ButtonTxt({
  callback,
  name = "",
  text = "",
  state,
  hoverText = null,
  hoverTextRight = false,
  hoverTextLeft = false,
  primary = false,
  isAccent = false,
  fill = false,
  isLoading = false,
  type = "button",
}) {
  const ref = useRef();
  const hover = useRef();

  const { contextSafe } = useGSAP({ scope: hover });

  const onHover = contextSafe(() => {
    if (hoverText) {
      gsap.to(hover.current, {
        opacity: 1,
        duration: 0.3,
        scale: 1,
      });
    }
  });

  const onMouseLeave = contextSafe(() => {
    if (hoverText) {
      gsap.to(hover.current, {
        opacity: 0,
        duration: 0.3,
        scale: 0.5,
      });
    }
  });

  const onMouseMove = contextSafe((e) => {
    if (hoverText) {
      if (hoverTextRight) {
        const y = e.clientY - e.target.getBoundingClientRect().top - hover.current.clientHeight / 2;

        gsap.to(hover.current, {
          y: `${y}px`,
        });
      } else if (hoverTextLeft) {
        const y = e.clientY - e.target.getBoundingClientRect().top - hover.current.clientHeight / 2;

        gsap.to(hover.current, {
          y: `${y}px`,
        });
      } else {
        gsap.set(hover.current, {
          y: `${-55}px`,
        });
      }
    }
  });

  useGSAP(
    () => {
      if (hoverTextRight) {
        gsap.set(hover.current, {
          x: `${ref.current?.clientWidth + 75}px`,
        });
      } else if (hoverTextLeft) {
        gsap.set(hover.current, {
          x: `${-ref.current?.clientWidth - 75}px`,
        });
      } else {
        gsap.set(hover.current, {
          y: `${-55}px`,
        });
      }
    },
    { dependencies: [hoverTextRight, hoverTextLeft, ref.current] }
  );

  return (
    <button
      type={type}
      style={{ flex: fill ? 1 : 0 }}
      ref={ref}
      onMouseEnter={onHover}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onClick={callback}
      className={`${primary ? btnStyles.btn__primary : ""}  ${btnStyles.btn} ${
        state ? btnStyles.btn__txt__active : ""
      } ${btnStyles.btn__txt} ${isAccent ? btnStyles.btn__accent : ""}`}
      name={name}
    >
      {hoverText && (
        <div className={btnStyles.tooltip} ref={hover}>
          {hoverText}
        </div>
      )}
      {isLoading && <LoadingIcon isPrimary={primary} />}
      <span>{text}</span>
    </button>
  );
}

function countDecimalPlaces(number) {
  if (typeof number !== "number") {
    throw new Error("Input must be a number.");
  }

  // Convert the number to a string
  const numberString = number.toString();

  // Check if the number has a decimal point
  if (numberString.includes(".")) {
    // Split the string at the decimal point
    const parts = numberString.split(".");

    // If there are two parts, the second part represents the decimal places
    if (parts.length === 2) {
      return parts[1].length;
    } else {
      // If there's only one part after the decimal point, there are no decimal places
      return 0;
    }
  } else {
    // If there is no decimal point, there are no decimal places
    return 0;
  }
}

function calculatePercentage(min, max, value) {
  if (min >= max) {
    throw new Error("Min must be less than Max");
  }

  if (value < min) {
    return 0;
  } else if (value > max) {
    return 100;
  } else {
    return ((value - min) / (max - min)) * 100;
  }
}

export function RangeInput({ name, inputName, callback, value, min, max, step, disabled = false }) {
  const ref = useRef();
  const currentValue = useRef();
  const thumb = useRef(null);
  const thumbInner = useRef(null);
  const gradient = useRef(null);

  const add = () => {
    const custom = {
      name: inputName,
      value: Number(value) + Number(step),
    };

    if (custom.value >= min && custom.value <= max) {
      const decimalPlaces = countDecimalPlaces(step);

      if (decimalPlaces === 0) {
        callback("", custom);
      } else {
        custom.value = custom.value.toFixed(decimalPlaces);

        callback("", custom);
      }
    }
  };

  const substract = () => {
    const custom = {
      name: inputName,
      value: Number(value) - Number(step),
    };

    if (custom.value >= min && custom.value <= max) {
      const decimalPlaces = countDecimalPlaces(step);

      if (decimalPlaces === 0) {
        callback("", custom);
      } else {
        custom.value = custom.value.toFixed(decimalPlaces);

        callback("", custom);
      }
    }
  };

  useGSAP(
    () => {
      const progress = calculatePercentage(min, max, value);

      gsap.to(gradient.current, {
        background: `linear-gradient(to right, var(--active-btn-color) ${progress}%, var(--toggle-bg-color) ${progress}%)`,
        duration: 0,
      });

      const inputLength = gradient.current.clientWidth;
      const thumbLength = thumbInner.current.clientWidth;

      gsap.to(thumb.current, {
        x: (progress / 100) * inputLength - (progress / 100) * thumbLength,
        duration: 0,
      });
    },
    {
      dependencies: [value, gradient, thumbInner],
    }
  );

  const handleInput = (e) => {
    const value = e.target.value;
    if (value !== "" && value >= min && value <= max) {
      callback(e);
    }
  };

  return (
    <div
      ref={ref}
      className={inputStyles.input__wrapper}
      style={{ opacity: disabled && 0.2, pointerEvents: disabled && "none" }}
    >
      <FlexWrapper direction="row" padding="0" extra={{ position: "relative" }} fill>
        <FlexWrapper
          padding="0"
          alignItems="center"
          justifyContent="space-between"
          extra={{ flex: 1 }}
        >
          <span>{name}</span>
          <input
            type="number"
            name={inputName}
            onChange={handleInput}
            value={value}
            style={{ flex: "unset", maxWidth: "100px", textAlign: "center" }}
          />
          {/* <span>{value}</span> */}
        </FlexWrapper>
        <FlexWrapper
          padding="0"
          gap=".25rem"
          direction="row"
          alignItems="center"
          extra={{ position: "relative", flex: 2 }}
          fill
        >
          <div onClick={substract} className={btnStyles.input__secondary__btn}>
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.5 8.25V6.75H12.5V8.25H2.5Z" fill="white" />
            </svg>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              flex: 1,
              borderRadius: "5px",
            }}
          >
            <div
              ref={thumb}
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                transform: "translateY(-50%)",
                width: "100%",
                pointerEvents: "none",
                zIndex: 1,
              }}
            >
              <div
                ref={thumbInner}
                style={{
                  position: "relative",
                  width: "56px",
                  height: "31px",
                  background: "var(--toggle-inner-color)",
                  borderRadius: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "3px",
                  gap: "3px",
                  boxShadow:
                    "0 0 1px 1px var(--shadow-btn), 0 1px 1px 0px var(--shadow-btn),0 2px 2px 0px var(--shadow-btn), 0 4px 4px 0px var(--shadow-btn),0 8px 8px 0px var(--shadow-btn), 0 16px 16px 0px var(--shadow-btn)",
                }}
              >
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "var(--toggle-linear-gradient)",
                    borderRadius: "50px",
                  }}
                />
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    background: "var(--toggle-linear-gradient-2)",
                    borderRadius: "50px",
                  }}
                />
              </div>
            </div>
            <div
              ref={gradient}
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                width: "100%",
                height: "5px",
                transform: "translateY(-50%)",
                borderRadius: "5px",
                zIndex: 0,
                pointerEvents: "none",
              }}
            />
            <input
              type="range"
              name={inputName}
              onChange={callback}
              value={value}
              min={min}
              max={max}
              step={step}
            />
          </div>
          <div onClick={add} className={btnStyles.input__secondary__btn}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.46094 7.82634H7.84094V3.45508H9.08094V7.82634H13.4609V9.06386H9.08094V13.4551H7.84094V9.06386H3.46094V7.82634Z"
                fill="white"
              />
            </svg>
          </div>
        </FlexWrapper>
      </FlexWrapper>
    </div>
  );
}

export function FlexWrapper({
  children,
  direction = "row",
  padding = "var(--padding-1)",
  gap = "var(--gap-1)",
  justifyContent = "unset",
  alignItems = "unset",
  extra,
  background = "",
  border = false,
  backgroundBlur = false,
  shadow = false,
  fill = false,
}) {
  return (
    <div
      style={{
        flexDirection: direction,
        gap: gap,
        padding: padding,
        justifyContent: justifyContent,
        alignItems: alignItems,
        background: background,
        borderRadius: "var(--border-radius)",
        backdropFilter: backgroundBlur ? "blur(50px)" : "unset",
        WebkitBackdropFilter: backgroundBlur ? "blur(50px)" : "unset",
        boxShadow: shadow ? "0 0 40px var(--shadow-color)" : "unset",
        flex: fill && 1,
        ...extra,
      }}
      className={wrapperStyles.flex__wrapper}
    >
      {children}
    </div>
  );
}

export function GridWrapper({
  children,
  padding = "var(--padding-1)",
  gap = "var(--gap-1)",
  colCount = 2,
  adaptive = false,
  extra,
}) {
  const [adaptiveColCount, setAdaptiveColCount] = useState(null);
  const size = useWindowSize();

  useEffect(() => {
    if (adaptive) {
      if (size.width <= 600) {
        setAdaptiveColCount(1);
      } else if (size.width <= 1200) {
        setAdaptiveColCount(2);
      } else if (size.width > 1600) {
        setAdaptiveColCount(3);
      }
    }
  }, [size]);

  return (
    <div
      style={{
        gap: gap,
        gridTemplateColumns: `repeat(${adaptive ? adaptiveColCount : colCount}, 1fr)`,
        padding: padding,
        ...extra,
        height: "100%",
      }}
      className={wrapperStyles.grid__wrapper}
    >
      {children}
    </div>
  );
}

export function GoogleFontsDropdown({ children }) {
  const [visible, setvisible] = useState(false);
  const content = useRef();

  useGSAP(
    () => {
      if (visible) {
        gsap.to(content.current, {
          height: "auto",
          opacity: 1,
          pointerEvents: "all",
        });
      } else {
        gsap.to(content.current, {
          height: "0px",
          opacity: 0,
          pointerEvents: "none",
        });
      }
    },
    {
      scope: content,
      dependencies: [visible],
    }
  );

  const handleDropDown = (e) => {
    if (visible) {
      setvisible(false);
    } else {
      setvisible(true);
    }
  };

  return (
    <div className={`${btnStyles.btn__extended__wrapper}`}>
      <button
        onClick={handleDropDown}
        className={`${btnStyles.btn__extended} ${visible && btnStyles.btn__extended__active}`}
      >
        <FlexWrapper
          padding="0"
          gap="0"
          justifyContent="space-between"
          alignItems="center"
          extra={{ width: "100%" }}
        >
          <FlexWrapper gap="0" padding="0" alignItems="center">
            <div className={btnStyles.btn__extended__icon}>
              <GoogleIcon />
            </div>
            <span>Google fonts</span>
          </FlexWrapper>
          <div
            className={`${btnStyles.btn__extended__icon} ${
              visible && btnStyles.btn__extended__icon__active
            }`}
          >
            <DropDownIcon />
          </div>
        </FlexWrapper>
      </button>
      <div
        style={{
          position: "relative",
          height: "0px",
          padding: "0 1rem",
          paddingTop: "0",
          overflow: "hidden",
        }}
        ref={content}
      >
        {children}
      </div>
    </div>
  );
}

export function CustomDropDown({
  children,
  name,
  defaultVisible = false,
  collapse = false,
  icon = null,
}) {
  const [visible, setvisible] = useState(defaultVisible ? true : false);
  const content = useRef();

  const handleDropdown = () => {
    if (visible) {
      setvisible(false);
    } else {
      setvisible(true);
    }
  };

  useGSAP(
    () => {
      if (visible) {
        gsap.to(content.current, {
          height: "auto",
          opacity: 1,
        });
      } else {
        gsap.to(content.current, {
          height: "0px",
          opacity: 0,
        });
      }
    },
    { scope: content, dependencies: [visible] }
  );

  useEffect(() => {
    if (defaultVisible) {
      if (collapse) {
        setvisible(false);
      } else {
        setvisible(true);
      }
    }
  }, [collapse]);

  return (
    <div className={`${btnStyles.btn__extended__wrapper}`}>
      <button
        onClick={handleDropdown}
        className={`${btnStyles.btn__extended} ${visible && btnStyles.btn__extended__active}`}
      >
        <FlexWrapper
          padding="0"
          gap="0"
          justifyContent="space-between"
          alignItems="center"
          extra={{ width: "100%" }}
          background=""
        >
          <FlexWrapper gap="0" padding="0" alignItems="center">
            <div className={btnStyles.btn__extended__icon}>
              {icon ? (
                <Icons icon={icon} />
              ) : (
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z"
                    fill="#D9D9D9"
                  />
                </svg>
              )}
            </div>
            <span>{name}</span>
          </FlexWrapper>
          <div
            className={`${btnStyles.btn__extended__icon} ${
              visible && btnStyles.btn__extended__icon__active
            }`}
          >
            <DropDownIcon />
          </div>
        </FlexWrapper>
      </button>
      <div
        style={{
          height: "0px",
          padding: "0 1rem",
          overflow: "hidden",
        }}
        ref={content}
      >
        {children}
      </div>
    </div>
  );
}

export function CustomHexColorPicker({ callback, color }) {
  return (
    <div
      style={{
        height: "300px",
        // overflow: "hidden",
        transition: ".3s",
        position: "relative",
      }}
    >
      <div className={colorpickerStyles.custom__color__picker}>
        <HexColorPicker onChange={callback} color={color} />
      </div>
    </div>
  );
}

export function AmbientDropdown({ children, enabled = true, callback, btnName = "" }) {
  const [visible, setvisible] = useState(false);

  const content = useRef();

  useGSAP(
    () => {
      if (visible) {
        gsap.to(content.current, {
          height: "auto",
          opacity: 1,
        });
      } else {
        gsap.to(content.current, {
          height: "0px",
          opacity: 0,
        });
      }
    },
    {
      scope: content,
      dependencies: [visible],
    }
  );

  const handleDropDown = (e) => {
    if (e.target.name !== btnName) {
      if (visible) {
        setvisible(false);
      } else {
        setvisible(true);
      }
    }
  };

  return (
    <div className={`${btnStyles.btn__extended__wrapper}`}>
      <div
        onClick={handleDropDown}
        className={`${btnStyles.btn__extended} ${visible && btnStyles.btn__extended__active} btn`}
      >
        <FlexWrapper
          padding="0"
          gap="0"
          justifyContent="space-between"
          alignItems="center"
          extra={{ width: "100%" }}
        >
          <FlexWrapper gap="0" padding="0" alignItems="center" extra={{ flex: 1 }}>
            <div className={btnStyles.btn__extended__icon}>
              <SunIcon />
            </div>
            <span>Ambient light</span>
          </FlexWrapper>
          <FlexWrapper
            padding="0"
            gap=".5rem"
            alignItems="center"
            extra={{ flex: 1, textAlign: "left" }}
          >
            <Toggle name={btnName} state={enabled} callback={callback} />
          </FlexWrapper>
          <div
            className={`${btnStyles.btn__extended__icon} ${
              visible && btnStyles.btn__extended__icon__active
            }`}
          >
            <DropDownIcon />
          </div>
        </FlexWrapper>
      </div>
      <div
        style={{
          height: "0px",
          padding: "0 1rem",
          overflow: "hidden",
        }}
        ref={content}
      >
        {children}
      </div>
    </div>
  );
}

export function SpotlightDropdown({ name, children, enabled = false, callback, btnName = "" }) {
  const [visible, setvisible] = useState(false);

  const content = useRef();

  useGSAP(
    () => {
      if (visible) {
        gsap.to(content.current, {
          height: "auto",
          opacity: 1,
        });
      } else {
        gsap.to(content.current, {
          height: "0px",
          opacity: 0,
        });
      }
    },
    {
      scope: content,
      dependencies: [visible],
    }
  );

  const handleDropDown = (e) => {
    if (e.target.name !== btnName) {
      if (visible) {
        setvisible(false);
      } else {
        setvisible(true);
      }
    }
  };

  return (
    <div className={`${btnStyles.btn__extended__wrapper}`}>
      <div
        onClick={handleDropDown}
        className={`${btnStyles.btn__extended} ${visible && btnStyles.btn__extended__active} btn`}
      >
        <FlexWrapper
          padding="0"
          gap="0"
          justifyContent="space-between"
          alignItems="center"
          extra={{ width: "100%" }}
        >
          <FlexWrapper gap="0" padding="0" alignItems="center" extra={{ flex: 1 }}>
            <div className={btnStyles.btn__extended__icon}>
              <SpotlightIcon />
            </div>
            <span>{name}</span>
          </FlexWrapper>
          <FlexWrapper
            padding="0"
            gap=".5rem"
            alignItems="center"
            extra={{ flex: 1, textAlign: "left" }}
          >
            <Toggle name={btnName} state={enabled} callback={callback} />
          </FlexWrapper>
          <div
            className={`${btnStyles.btn__extended__icon} ${
              visible && btnStyles.btn__extended__icon__active
            }`}
          >
            <DropDownIcon />
          </div>
        </FlexWrapper>
      </div>
      <div
        style={{
          height: "0px",
          padding: "0 1rem",
          overflow: "hidden",
        }}
        ref={content}
      >
        {children}
      </div>
    </div>
  );
}

export function CanvasLowRes({ children }) {
  const { frameloop } = useSelector((state) => state.frameloop);
  const dispatch = useDispatch();
  const canvas = useRef();

  const [rotation, setrotation] = useState(false);

  const handleAnimation = () => {
    if (rotation) {
      setrotation(false);
    } else {
      setrotation(true);
    }
  };

  const { active, progress } = useProgress();

  useEffect(() => {
    if (active) {
      dispatch(setProgress(Math.round(progress)));
    }
    if (progress === 100) {
      dispatch(setIsLoading(false));
      dispatch(setProgress(100));
    }
  }, [progress, active]);

  return (
    <div style={{ position: "relative", zIndex: 100, width: "100%", height: "100%" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          right: "1rem",
          transform: "translateY(-50%)",
          zIndex: 10,
        }}
      >
        <ButtonSVG callback={handleAnimation} state={rotation}>
          <RotateIcon />
        </ButtonSVG>
      </div>
      <Canvas
        resize={{ debounce: 1 }}
        shadows
        ref={canvas}
        dpr={1}
        frameloop={frameloop || rotation ? "always" : "demand"}
        // frameloop="demand"
        style={{ background: "var(--bg-color)" }}
        gl={{
          outputEncoding: sRGBEncoding,
          toneMapping: ACESFilmicToneMapping,
          toneMappingExposure: 1,
          antialias: true,
        }}
        // linear
      >
        <FlashLight />
        <ImageExportOnSave />
        <Suspense fallback={null}>
          <Center>{children}</Center>
          <Environment files="/img/dancing_hall_1k.hdr" preset={null} />
          <OrbitControls
            enablePan={false}
            minPolarAngle={Math.PI / 3.9}
            maxPolarAngle={Math.PI / 1.9}
            maxDistance={3}
            minDistance={1.5}
            makeDefault
            autoRotate={rotation}
            autoRotateSpeed={4}
          />
          <PerspectiveCamera makeDefault position={[0, 0, 3]} fov={35} zoom={1.5} />
          <Preload all />
        </Suspense>
      </Canvas>
    </div>
  );
}

function FlashLight() {
  const { lightSettings } = useSelector((state) => state.configurator3D);
  const spotRef = useRef();

  useFrame(({ camera }) => {
    const targetX = camera.position.x;
    // const targetY = lightSettings.flashlightAdaptive
    //   ? lightSettings.flashlightOffsetY
    //   : camera.position.y;
    const targetY = lightSettings.flashlightOffsetY;
    const targetZ = camera.position.z;

    const clampedX = Math.max(-1, Math.min(1, targetX));
    const clampedY = Math.max(-1, Math.min(1, targetY));
    const clampedZ = Math.max(-1, Math.min(1, targetZ));

    spotRef.current.position.set(clampedX, clampedY, clampedZ);
  });

  return (
    <>
      <spotLight
        ref={spotRef}
        shadow-mapSize-width={4096} // Set the shadow map width
        shadow-mapSize-height={4096} // Set the shadow map height
        shadow-camera-far={50} // Set the shadow camera far distance
        shadow-camera-near={0.1}
        shadow-bias={0}
        penumbra={1}
        intensity={lightSettings.flashlightIntensity}
        angle={Math.PI / 4}
        color="#fff"
        distance={0}
        castShadow
      />
    </>
  );
}

export function CanvasHighRes({ children }) {
  const { extraSettings, renderImages, aspectRatio, modelSettings, selectedHDR, imageSettings } =
    useSelector((state) => state.configurator3D);
  const dispatch = useDispatch();
  const canvas = useRef();
  const parent = useRef();

  const { active, progress } = useProgress();

  const [dimensions, setDimension] = useState({
    width: 0,
    height: 0,
    highresWidth: 0,
    highresHeight: 0,
  });
  const [dpr, setdpr] = useState(2);

  const model = useRef();

  useEffect(() => {
    if (active) {
      dispatch(setProgress(Math.round(progress)));
    }
    if (progress === 100) {
      dispatch(setIsLoading(false));
      dispatch(setProgress(100));
    }
  }, [progress, active]);

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [aspectRatio, imageSettings]);

  const handleResize = () => {
    if (parent.current) {
      if (aspectRatio === "16:9") {
        const height = ((parent.current.parentElement.offsetWidth / 2 - 20) * 9) / 16;
        const width = parent.current.parentElement.offsetWidth / 2 - 20;

        const highresHeight = imageSettings.imageQuality === "4k" ? 2160 / 2 : 2160 / 4;
        const highresWidth =
          imageSettings.imageQuality === "4k" ? (2160 * (16 / 9)) / 2 : (2160 * (16 / 9)) / 4;

        setDimension({
          width: width,
          height: height,
          highresWidth: highresWidth,
          highresHeight: highresHeight,
        });
      } else if (aspectRatio === "9:16") {
        const height = parent.current.parentElement.offsetHeight - 40;
        const width = (9 / 16) * (parent.current.parentElement.offsetHeight - 40);

        const highresHeight = imageSettings.imageQuality === "4k" ? 2160 / 2 : 2160 / 4;
        const highresWidth =
          imageSettings.imageQuality === "4k" ? (2160 * (9 / 16)) / 2 : (2160 * (16 / 9)) / 4;

        setDimension({
          width: width,
          height: height,
          highresWidth: highresWidth,
          highresHeight: highresHeight,
        });
      } else {
        const height = parent.current.parentElement.offsetWidth / 2 - 30;
        const width = parent.current.parentElement.offsetWidth / 2 - 30;

        const highresHeight = imageSettings.imageQuality === "4k" ? 2048 : 1024;
        const highresWidth = imageSettings.imageQuality === "4k" ? 2048 : 1024;

        setDimension({
          width: width,
          height: height,
          highresWidth: highresWidth,
          highresHeight: highresHeight,
        });
      }
    }
  };

  return (
    <div
      ref={parent}
      style={{
        position: "relative",
        width: `100%`,
        height: `100%`,
        zIndex: 100,
        display: "flex",
        alignItems: "center",
        background: "var(--bg-color)",
      }}
    >
      <Loader />
      <Canvas
        resize={{ debounce: 1 }}
        shadows={"soft"}
        ref={canvas}
        dpr={dpr}
        frameloop={"demand"}
        performance={{ min: 0.15 }}
        style={{
          borderRadius: "var(--border-radius)",
          overflow: "hidden",
          width: renderImages ? `${dimensions.highresWidth}px` : `${dimensions.width}px`,
          height: renderImages ? `${dimensions.highresHeight}px` : `${dimensions.height}px`,
          background: "transparent",
        }}
        gl={{
          outputEncoding: sRGBEncoding,
          toneMapping: ACESFilmicToneMapping,
          toneMappingExposure: extraSettings.exposure,
          antialias: true,
          alpha: true,
        }}
        linear
        flat
      >
        <ImageExportOnSave />
        <ImageExport />
        <CanvasBackground />
        <CanvasCameraPosition />
        <Suspense fallback={null}>
          <Environment files={selectedHDR} preset={null} />
        </Suspense>
        <Suspense fallback={null}>
          <Center
            ref={model}
            position={[modelSettings.offsetX, modelSettings.offsetY, modelSettings.offsetZ]}
          >
            {children}
          </Center>
        </Suspense>
        <CanvasGround />
        <HDREnvironment />
        <AdaptiveDpr pixelated />
        <OrbitControls
          enablePan={false}
          minPolarAngle={Math.PI / 3.9}
          maxPolarAngle={Math.PI / 1.9}
          maxDistance={3}
          minDistance={1.5}
          enabled
          makeDefault
          regress
        />
        <CameraControls />
        <Preload all />
        {/* <Stats /> */}
      </Canvas>
    </div>
  );
}

function ImageExport() {
  const dispatch = useDispatch();
  const { imageSettings, renderImages } = useSelector((state) => state.configurator3D);

  const { gl, scene, camera } = useThree();

  useEffect(() => {
    if (renderImages) {
      setTimeout(() => {
        gl.render(scene, camera);

        const image = gl.domElement.toDataURL(`image/${imageSettings.imageFormat}`, 0.7);
        const link = document.createElement("a");

        link.download = "content";
        link.href = image;
        link.click();

        dispatch(setRenderImages(false));
        dispatch(setIsLoading(false));
      }, 2500);
    }
  }, [renderImages]);
}

function ImageExportOnSave() {
  const dispatch = useDispatch();
  const { createProjectImage } = useSelector((state) => state.project);

  const { gl, scene, camera } = useThree();

  useEffect(() => {
    if (createProjectImage) {
      // camera.position.set(0, 0, 3);
      // camera.zoom = 2.25;

      setTimeout(() => {
        gl.render(scene, camera);

        const image = gl.domElement.toDataURL(`image/webp`, 0.7);

        const blob = dataURItoBlob(image);
        const blobUrl = URL.createObjectURL(blob);

        dispatch(setProjectImage(blobUrl));
        dispatch(setCreateProjectImage(false));
        // dispatch(setIsLoading(false));
      }, 2500);
    }
  }, [createProjectImage]);
}

function CameraControls() {
  const { cameraSettings } = useSelector((state) => state.configurator3D);

  return (
    <PerspectiveCamera
      makeDefault
      position={[0, 0, 3]}
      fov={cameraSettings.cameraFov}
      zoom={cameraSettings.cameraZoom}
    />
  );
}

function CanvasCameraPosition() {
  const { cameraSettings } = useSelector((state) => state.configurator3D);
  const { camera } = useThree();

  useEffect(() => {
    camera.position.set(
      cameraSettings.cameraPosition.x,
      cameraSettings.cameraPosition.y,
      cameraSettings.cameraPosition.z
    );
  }, [cameraSettings]);
}

function CanvasGround() {
  const { groundSettings, envIntensity } = useSelector((state) => state.configurator3D);
  return (
    <>
      {groundSettings.groundEnabled && (
        <Plane
          // receiveShadow
          args={[10, 10]}
          position={[0, -0.36, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <meshStandardMaterial color={groundSettings.groundColor} envMapIntensity={envIntensity} />
        </Plane>
      )}
    </>
  );
}

function CanvasBackground() {
  const { backgroundColor, transparentBackground, groundSettings, envIntensity } = useSelector(
    (state) => state.configurator3D
  );
  return (
    <>
      {groundSettings.groundEnabled && (
        <fog attach="fog" color={backgroundColor} near={2} far={6} />
      )}
      {transparentBackground === false && <color attach="background" args={[backgroundColor]} />}
      {groundSettings.groundEnabled && (
        <Plane
          receiveShadow
          args={[10, 10]}
          position={[0, -0.36, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <meshStandardMaterial color={groundSettings.groundColor} envMapIntensity={envIntensity} />
        </Plane>
      )}
    </>
  );
}

function HDREnvironment() {
  const { lightSettings } = useSelector((state) => state.configurator3D);
  const top = useRef();
  const left = useRef();
  const right = useRef();
  const front = useRef();
  const back = useRef();

  return (
    <>
      {lightSettings.flashlightEnabled && <FlashLight />}
      <ambientLight
        visible={lightSettings.ambientLightEnabled}
        intensity={lightSettings.ambientLightIntensity}
        color="#f2f2f2"
      />
      <spotLight
        shadow-mapSize-width={4096} // Set the shadow map width
        shadow-mapSize-height={4096} // Set the shadow map height
        shadow-camera-far={50} // Set the shadow camera far distance
        shadow-camera-near={0.1}
        shadow-bias={0}
        castShadow={lightSettings.topSpotlightCastShadows}
        ref={top}
        visible={lightSettings.topSpotlightEnabled}
        intensity={lightSettings.topSpotlightIntensity}
        angle={Math.PI / 4}
        distance={lightSettings.topSpotlightAttenuation}
        penumbra={1}
        color={lightSettings.topSpotlightColor}
        position={[lightSettings.topSpotlightX, 1, lightSettings.topSpotlightZ]}
      />
      <spotLight
        shadow-mapSize-width={4096} // Set the shadow map width
        shadow-mapSize-height={4096} // Set the shadow map height
        shadow-camera-far={50} // Set the shadow camera far distance
        shadow-camera-near={0.1}
        shadow-bias={0}
        castShadow={lightSettings.frontSpotlightCastShadows}
        visible={lightSettings.frontSpotlightEnabled}
        ref={front}
        intensity={lightSettings.frontSpotlightIntensity}
        angle={Math.PI / 4}
        distance={lightSettings.frontSpotlightAttenuation}
        penumbra={1}
        color={lightSettings.frontSpotlightColor}
        position={[lightSettings.frontSpotlightX, lightSettings.frontSpotlightY, 1]}
      />
      <spotLight
        shadow-mapSize-width={4096} // Set the shadow map width
        shadow-mapSize-height={4096} // Set the shadow map height
        shadow-camera-far={50} // Set the shadow camera far distance
        shadow-camera-near={0.1}
        shadow-bias={0}
        castShadow={lightSettings.backSpotlightCastShadows}
        visible={lightSettings.backSpotlightEnabled}
        ref={back}
        intensity={lightSettings.backSpotlightIntensity}
        angle={Math.PI / 4}
        distance={lightSettings.backSpotlightAttenuation}
        penumbra={1}
        color={lightSettings.backSpotlightColor}
        position={[lightSettings.backSpotlightX, lightSettings.backSpotlightY, -1]}
      />
      <spotLight
        shadow-mapSize-width={4096} // Set the shadow map width
        shadow-mapSize-height={4096} // Set the shadow map height
        shadow-camera-far={50} // Set the shadow camera far distance
        shadow-camera-near={0.1}
        shadow-bias={0}
        castShadow={lightSettings.leftSpotlightCastShadows}
        visible={lightSettings.leftSpotlightEnabled}
        ref={left}
        intensity={lightSettings.leftSpotlightIntensity}
        angle={Math.PI / 4}
        distance={lightSettings.leftSpotlightAttenuation}
        penumbra={1}
        color={lightSettings.leftSpotlightColor}
        position={[-1, lightSettings.leftSpotlightY, lightSettings.leftSpotlightZ]}
      />
      <spotLight
        shadow-mapSize-width={4096} // Set the shadow map width
        shadow-mapSize-height={4096} // Set the shadow map height
        shadow-camera-far={50} // Set the shadow camera far distance
        shadow-camera-near={0.1}
        shadow-bias={0}
        castShadow={lightSettings.rightSpotlightCastShadows}
        visible={lightSettings.rightSpotlightEnabled}
        ref={right}
        intensity={lightSettings.rightSpotlightIntensity}
        angle={Math.PI / 4}
        distance={lightSettings.rightSpotlightAttenuation}
        penumbra={1}
        color={lightSettings.rightSpotlightColor}
        position={[1, lightSettings.rightSpotlightY, lightSettings.rightSpotlightZ]}
      />
      {/* {lightSettings.softShadowsEnabled && (
        <SoftShadows
          samples={lightSettings.shadowSamples}
          // samples={64}
          size={lightSettings.shadowSize}
          focus={lightSettings.shadowFocus}
        />
      )} */}
    </>
  );
}

export function ButtonNav({ onClose, name, children }) {
  return (
    <div className={btnStyles.btn__nav}>
      <FlexWrapper gap=".5rem" padding="0" alignItems="center">
        <ButtonSVG text="Back" callback={onClose} isBackBtn={true}>
          <BackIcon />
        </ButtonSVG>
        <h3 className="small-heading">{name}</h3>
      </FlexWrapper>

      <FlexWrapper padding="0" gap=".5rem" justifyContent="flex-end" extra={{ flex: 1 }}>
        {children}
      </FlexWrapper>
    </div>
  );
}

export function Loader() {
  const dispatch = useDispatch();
  const { isLoading, progress } = useSelector((state) => state.loader);

  const loader = useRef();
  const progressRef = useRef();

  useGSAP(
    () => {
      if (isLoading) {
        gsap.to(loader.current, {
          opacity: 1,
          pointerEvents: "all",
          delay: 0,
        });
      } else {
        gsap.to(loader.current, {
          opacity: 0,
          pointerEvents: "none",
          delay: 2,
          onComplete: () => dispatch(setProgress(0)),
        });
      }
    },
    { dependencies: [isLoading] }
  );

  useGSAP(
    () => {
      gsap.to(progressRef.current, {
        innerText: progress,
        duration: 0.1,
        snap: {
          innerText: 1,
        },
      });
    },
    {
      dependencies: [progress],
    }
  );

  return (
    <div ref={loader} className={loaderStyles.loader}>
      <span ref={progressRef} className={loaderStyles.progress}>
        {progress}
      </span>
      <div className={loaderStyles.logo__wrapper}>
        <div className={loaderStyles.loader__inner}>
          <div className={loaderStyles.dot}></div>
          <div className={loaderStyles.dot}></div>
          <div className={loaderStyles.dot}></div>
          <div className={loaderStyles.dot}></div>
          <div className={loaderStyles.dot}></div>
          <div className={loaderStyles.dot}></div>
          <div className={loaderStyles.dot}></div>
          <div className={loaderStyles.dot}></div>
        </div>
      </div>
    </div>
  );
}

export function Toggle({ state, callback, name = "", disabled = false }) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        callback(e);
      }}
      id={name}
      className={`${inputStyles.toggle__wrapper} ${disabled && btnStyles.btn__disabled}`}
      style={{ background: state && "var(--active-btn-color)", opacity: disabled && 0.2 }}
    >
      <div className={`${inputStyles.toggle__inner} ${state && inputStyles.toggle__active}`} />
      <div className={`${inputStyles.toggle__label} ${state && inputStyles.toggle__label__active}`}>
        <div
          style={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "2px",
              height: "10px",
              borderRadius: "2px",
              background: "var(--toggle-inner-color)",
            }}
          />
        </div>
        <div
          style={{
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50px",
              outline: "2px solid var(--toggle-inner-color)",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function ContextButton({ callback, children }) {
  return (
    <button className={btnStyles.context__btn} onClick={callback}>
      {children}
    </button>
  );
}

export function JellyLoader({ state }) {
  const ref = useRef();

  useGSAP(
    () => {
      if (state) {
        gsap.to(ref.current, {
          opacity: 1,
          pointerEvents: "all",
          ease: "power3.out",
        });
      } else {
        gsap.to(ref.current, {
          opacity: 0,
          pointerEvents: "none",
          ease: "power3.out",
          delay: 1,
        });
      }
    },
    { scope: ref, dependencies: [state] }
  );

  return (
    <div ref={ref} className={loaderStyles.secondary__loader}>
      <div className={loaderStyles.loader__inner}>
        <div className={loaderStyles.dot}></div>
        <div className={loaderStyles.dot}></div>
        <div className={loaderStyles.dot}></div>
        <div className={loaderStyles.dot}></div>
        <div className={loaderStyles.dot}></div>
        <div className={loaderStyles.dot}></div>
        <div className={loaderStyles.dot}></div>
        <div className={loaderStyles.dot}></div>
      </div>
    </div>
  );
}

export function Spacer() {
  return <div style={{ width: "100%", height: "1px", borderTop: "1px solid transparent" }} />;
}

export function PatternDropdown({ state, children, onCloseCallback }) {
  const ref = useRef();

  useGSAP(
    () => {
      if (state) {
        gsap.to(ref.current, {
          opacity: 1,
          pointerEvents: "all",
        });
      } else {
        gsap.to(ref.current, {
          opacity: 0,
          pointerEvents: "none",
        });
      }
    },
    { scope: ref, dependencies: [state] }
  );

  return (
    <div
      ref={ref}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "var(--bg-color)",
        opacity: 0,
        pointerEvents: "none",
        display: "flex",
        flexDirection: "column",
        // gap: "0.5rem",
        padding: "1rem",
      }}
    >
      <ButtonNav onClose={onCloseCallback} name="Pattern selector" />
      <FlexWrapper
        direction="column"
        padding="0"
        extra={{
          position: "relative",
          flex: 1,
          height: "100%",
        }}
      >
        {children}
      </FlexWrapper>
    </div>
  );
}

export function Checkbox({ state, callback, name }) {
  return (
    <>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={state}
        onChange={callback}
        style={{ background: state ? "var(--active-btn-color)" : "transparent" }}
      />
      <label
        style={{
          position: "absolute",
          left: "0",
          transform: "translateX(4px)",
          transition: ".3s",
          pointerEvents: "none",
        }}
        htmlFor={name}
      >
        <svg
          style={{ width: "18px", height: "18px" }}
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_835_2)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.7583 4.24645C12.9131 4.40412 13 4.61794 13 4.84088C13 5.06383 12.9131 5.27764 12.7583 5.43531L6.5713 11.7373C6.48954 11.8206 6.39247 11.8866 6.28563 11.9317C6.17879 11.9768 6.06428 12 5.94864 12C5.83299 12 5.71848 11.9768 5.61165 11.9317C5.50481 11.8866 5.40774 11.8206 5.32597 11.7373L2.252 8.60674C2.17316 8.52918 2.11028 8.43641 2.06702 8.33383C2.02375 8.23125 2.00098 8.12092 2.00003 8.00928C1.99908 7.89764 2.01996 7.78693 2.06147 7.6836C2.10297 7.58027 2.16426 7.4864 2.24177 7.40746C2.31927 7.32851 2.41144 7.26608 2.51288 7.22381C2.61432 7.18153 2.72302 7.16026 2.83262 7.16123C2.94222 7.1622 3.05054 7.18539 3.15125 7.22946C3.25196 7.27352 3.34304 7.33757 3.41919 7.41788L5.94836 9.99403L11.5906 4.24645C11.6672 4.16832 11.7583 4.10634 11.8584 4.06405C11.9586 4.02177 12.066 4 12.1745 4C12.2829 4 12.3903 4.02177 12.4905 4.06405C12.5906 4.10634 12.6817 4.16832 12.7583 4.24645Z"
              fill={state ? "var(--primary-text-color)" : "transparent"}
            />
          </g>
          <defs>
            <clipPath id="clip0_835_2">
              <rect width="15" height="15" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </label>
    </>
  );
}

export function AnimatedSkeleton({ isVisible = true }) {
  const ref = useRef();

  useGSAP(
    () => {
      if (isVisible) {
        gsap.to(ref.current, {
          opacity: 1,
          pointerEvents: "all",
        });
      } else {
        gsap.to(ref.current, {
          opacity: 0,
          pointerEvents: "none",
        });
      }
    },
    {
      dependencies: [isVisible],
    }
  );

  return (
    <div ref={ref} className={loaderStyles.skeleton__wrapper}>
      <div className={loaderStyles.skeleton__inner} />
    </div>
  );
}

export function Status({ status, text = null }) {
  if (status === "active") {
    return (
      <div className={`${btnStyles.status__wrapper} ${btnStyles.status__active}`}>
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_835_2)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.7583 4.24645C12.9131 4.40412 13 4.61794 13 4.84088C13 5.06383 12.9131 5.27764 12.7583 5.43531L6.5713 11.7373C6.48954 11.8206 6.39247 11.8866 6.28563 11.9317C6.17879 11.9768 6.06428 12 5.94864 12C5.83299 12 5.71848 11.9768 5.61165 11.9317C5.50481 11.8866 5.40774 11.8206 5.32597 11.7373L2.252 8.60674C2.17316 8.52918 2.11028 8.43641 2.06702 8.33383C2.02375 8.23125 2.00098 8.12092 2.00003 8.00928C1.99908 7.89764 2.01996 7.78693 2.06147 7.6836C2.10297 7.58027 2.16426 7.4864 2.24177 7.40746C2.31927 7.32851 2.41144 7.26608 2.51288 7.22381C2.61432 7.18153 2.72302 7.16026 2.83262 7.16123C2.94222 7.1622 3.05054 7.18539 3.15125 7.22946C3.25196 7.27352 3.34304 7.33757 3.41919 7.41788L5.94836 9.99403L11.5906 4.24645C11.6672 4.16832 11.7583 4.10634 11.8584 4.06405C11.9586 4.02177 12.066 4 12.1745 4C12.2829 4 12.3903 4.02177 12.4905 4.06405C12.5906 4.10634 12.6817 4.16832 12.7583 4.24645Z"
              fill="var(--primary-text-color)"
            />
          </g>
          <defs>
            <clipPath id="clip0_835_2">
              <rect width="15" height="15" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>{text ? text : "Active"}</span>
      </div>
    );
  }
  if (status === "warning") {
    return (
      <div className={`${btnStyles.status__wrapper} ${btnStyles.status__warning}`}>
        <span>{text ? text : "Warning"}</span>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 2C8.3 2 2 8.3 2 16C2 23.7 8.3 30 16 30C23.7 30 30 23.7 30 16C30 8.3 23.7 2 16 2ZM14.9 8H17.1V19H14.9V8ZM16 25C15.2 25 14.5 24.3 14.5 23.5C14.5 22.7 15.2 22 16 22C16.8 22 17.5 22.7 17.5 23.5C17.5 24.3 16.8 25 16 25Z"
            fill="var(--primary-text-color)"
          />
        </svg>
      </div>
    );
  }
}

export function AlertDialog({
  visible,
  onClose,
  children,
  body = null,
  header = null,
  icon = null,
}) {
  const ref = useRef();

  useGSAP(
    () => {
      if (visible) {
        gsap.to(ref.current, {
          opacity: 1,
          pointerEvents: "all",
        });
      } else {
        gsap.to(ref.current, {
          opacity: 0,
          pointerEvents: "none",
        });
      }
    },
    {
      scope: ref,
      dependencies: [visible],
    }
  );

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100dvh",
        opacity: 0,
        pointerEvents: "none",
        width: "100%",
        height: "100dvh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        background: "var(--transparent-color)",
        backdropFilter: "blur(50px)",
        WebkitBackdropFilter: "blur(50px)",
        zIndex: 999,
        overflow: "hidden",
      }}
      ref={ref}
    >
      <div
        onClick={onClose}
        style={{ position: "absolute", inset: 0, width: "100%", height: "100%" }}
      />
      <FlexWrapper
        direction="column"
        gap="0"
        padding="0"
        extra={{
          width: "clamp(300px,calc(100vw - 2rem),500px)",
          maxHeight: "calc(100dvh - 2rem)",
        }}
        shadow
      >
        <ButtonNav onClose={onClose} />
        <FlexWrapper
          direction="column"
          gap="2rem"
          extra={{
            borderRadius: 0,
            borderBottomLeftRadius: "var(--border-radius)",
            borderBottomRightRadius: "var(--border-radius)",
            overflowY: "auto",
            flex: 1,
            overflowX: "hidden",
            paddingTop: 0,
          }}
          background="var(--bg-color-secondary)"
        >
          {icon && (
            <FlexWrapper padding="0" justifyContent="center">
              {icon}
            </FlexWrapper>
          )}
          {(header || body) && (
            <FlexWrapper padding="0" direction="column" alignItems="center">
              {header && <h3 className="small-heading">{header}</h3>}
              {body && (
                <p style={{ textAlign: "center", color: "var(--secondary-text-color)" }}>{body}</p>
              )}
            </FlexWrapper>
          )}
          {children}
        </FlexWrapper>
      </FlexWrapper>
    </div>
  );
}
